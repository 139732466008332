// native
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// component
import { HomeComponent } from './home/components/home/home.component';
import { ResolverComponent } from './resolver/components/resolver/resolver.component';
import { SuccessComponent } from './resolver/components/success/success.component';

const routes: Routes = [{
  path: '',
  component: HomeComponent
},
{
  path: 'matcher/:collectionId',
  component: ResolverComponent
},
{
  path: 'success',
  component: SuccessComponent
},
{
  path: '**',
  redirectTo: ''
}];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }